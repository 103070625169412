<template>
  <div>
    <div class="BgWhiteBS vx-row w-full p-4">
      <div class="flex w-full mb-4" style="color: #434343; font-size: 1.2rem">
        <span @click="OpenCustomersPage" style="cursor: pointer"
          >Customers</span
        >
        <span class="ml-2 mr-2"> > </span>
        <span @click="OpenCustomerDetailPage" style="cursor: pointer">{{
          company.name
        }}</span>
        <span class="ml-2 mr-2"> > </span>
        <span style="font-weight: 600">Credits</span>
      </div>

      <div class="mt-2">
        Remaning Credit
        <span class="ml-2" style="font-weight: 600; color: #28c76f">
          {{ company.nokta_market_credit }}
        </span>
      </div>
    </div>

    <div class="vx-row mt-4">
      <div class="vx-col p-0 pr-2 md:w-1/2">
        <div class="BgWhiteBS h-full">
          <div class="flex justify-between p-4">
            <vs-icon
              icon="arrow_downward"
              size="small"
              color="#fff"
              bg="#157FED"
              round
            ></vs-icon>
            <div>
              Total Purchased Credits
              <span class="ml-2" style="color: #157fed; font-weight: 600">
                {{ company.purschased_credit_total }}
              </span>
            </div>
          </div>

          <div
            class="
              BgWhiteBS
              vx-row
              p-1
              m-0
              mt-4
              orderBar
              pt-3
              pb-3
              items-center
            "
          >
            <div class="vx-col md:w-1/4 text-center">Credit</div>
            <div class="vx-col md:w-1/4 text-center">Date</div>
            <div class="vx-col md:w-1/4 text-center">Payment Method</div>
            <div class="vx-col md:w-1/4 text-center">Status</div>
          </div>

          <div class="BgWhiteBS">
            <div
              class="vx-row p-1 pt-3 pb-3 items-center"
              v-for="(received, index) in purchasedCredits"
              :key="received.id"
              :style="
                index % 2 == 0
                  ? 'background-color: #f5f5f5'
                  : 'background-color: #fff'
              "
            >
              <div class="vx-col md:w-1/4 text-center orderId">
                {{ received.credit }}
              </div>

              <div class="vx-col md:w-1/4 text-center">
                {{ GetRelativeTime(received.created) }}
              </div>

              <div class="vx-col md:w-1/4 text-center">
                {{ received.payment_method }}
              </div>

              <div class="vx-col md:w-1/4 text-center">
                <div
                  class="mx-auto"
                  style="
                    width: 16px !important;
                    height: 16px;
                    border-radius: 50%;
                  "
                  :style="
                    'background-color: ' + GetStatusColor(received.status)
                  "
                ></div>
              </div>
            </div>
          </div>
          <div class="m-2 my-8">
            <vs-pagination
              :max="7"
              :total="totalPagePurchase"
              v-model="currentPagePurchase"
              @change="HandleChangePagePurchase(currentPagePurchase)"
            />
          </div>
        </div>
      </div>
      <div class="vx-col p-0 pl-2 md:w-1/2">
        <div class="BgWhiteBS h-full">
          <div class="flex justify-between p-4">
            <vs-icon
              icon="arrow_upward"
              size="small"
              color="#fff"
              bg="#F57373"
              round
            ></vs-icon>
            <div>
              Total Used Credits
              <span class="ml-2" style="color: #f57373; font-weight: 600">
                {{ company.used_credit }}
              </span>
            </div>
          </div>

          <div
            class="
              BgWhiteBS
              vx-row
              p-1
              m-0
              mt-4
              orderBar
              pt-3
              pb-3
              items-center
            "
          >
            <div class="vx-col md:w-1/4 text-center">Credit</div>
            <div class="vx-col md:w-1/4 text-center">Orders</div>
            <div class="vx-col md:w-1/4 text-center">Payment Method</div>
            <div class="vx-col md:w-1/4 text-center">Status</div>
          </div>

          <div class="BgWhiteBS">
            <div
              class="vx-row p-1 pt-3 pb-3 items-center"
              v-for="(spent, index) in usedCredits"
              :key="spent.id"
              :style="
                index % 2 == 0
                  ? 'background-color: #f5f5f5'
                  : 'background-color: #fff'
              "
            >
              <div class="vx-col md:w-1/4 text-center creditSpent">
                -{{ spent.total_weight }}
              </div>

              <div class="vx-col md:w-1/4 text-center orderId">
                #{{ spent.id }}
              </div>

              <div class="vx-col md:w-1/4 text-center">
                {{ GetRelativeTime(spent.created) }}
              </div>

              <div class="vx-col md:w-1/4 text-center">
                <div
                  class="mx-auto"
                  style="
                    width: 16px !important;
                    height: 16px;
                    border-radius: 50%;
                  "
                  :style="
                    'background-color: ' +
                    GetStatusColorSpent(spent.order_status)
                  "
                ></div>
              </div>
            </div>
          </div>
          <div class="m-2 my-8">
            <vs-pagination
              :max="7"
              :total="totalPageUsed"
              v-model="currentPageUsed"
              @change="HandleChangePageUsed(currentPageUsed)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/api/api";
import API from "@/api/config";

import moment from "moment";

export default {
  data() {
    return {
      company: {},

      totalPagePurchase: 1,
      currentPagePurchase: 1,

      totalPageUsed: 1,
      currentPageUsed: 1,

      purchasedCredits: [],
      usedCredits: [],
    };
  },
  methods: {
    OpenCustomersPage() {
      this.$router
        .push({
          path: "/nokta_admin_customer/",
        })
        .catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
    },
    OpenCustomerDetailPage() {
      this.$router
        .push({
          path: "/nokta_admin_customer/" + this.$route.params.id + "/",
        })
        .catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
    },
    GetRelativeTime(value) {
      return moment(String(value)).format("DD-MM-YY");
    },
    GetCredits() {
      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });

      let params = {};

      if (this.currentPagePurchase != 1) {
        params.page = this.currentPagePurchase;
      }

      Api.get(
        API.BASEURL +
          API.NOKTA_MARKET_PROVIDER_CUSTOMER +
          this.$route.params.id +
          "/credits/",
        this.HangleGetCredits,
        params
      );
    },
    HangleGetCredits(status, data) {
      this.$vs.loading.close();

      if (status == 200) {
        this.company = data.company;
        this.purchasedCredits = data.results;
        this.totalPagePurchase = Math.ceil(data.count / 50);
      }
    },
    HandleChangePagePurchase(page) {
      this.currentPagePurchase = page;
      this.GetCredits();
    },
    GetUsedCredits() {
      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });

      let params = {};

      if (this.currentPageUsed != 1) {
        params.page = this.currentPageUsed;
      }

      Api.get(
        API.BASEURL +
          API.NOKTA_MARKET_PROVIDER_CUSTOMER +
          this.$route.params.id +
          "/orders/",
        this.HangleGetUsedCredits,
        params
      );
    },
    HangleGetUsedCredits(status, data) {
      this.$vs.loading.close();

      if (status == 200) {
        this.usedCredits = data.results;
      }
    },
    HandleChangePageUsed(page) {
      this.currentPageUsed = page;
      this.GetUsedCredits();
    },
    GetStatusColor(status) {
      if (status == "2") return "#EA5455";
      if (status == "1") return "#28C76F";
      return "#C89E82";
    },
    GetStatusColorSpent(status) {
      if (status == "P") return "#FF9F43";
      if (status == "R") return "#28C76F";
      if (status == "C") return "#EA5455";
      if (status == "W") return "#389CA3";
      return "#C89E82";
    },
  },
};
</script>

<style>
.BgWhiteBS {
  background-color: white;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  color: #434343;
}
.vx-row,
.vx-col {
  padding: 0px;
  margin: 0px;
}
.orderBar {
  font-weight: 600;
  color: #626262;
}
.orderId {
  color: #117fed;
}
.creditSpent {
  color: #f57373;
}
</style>
